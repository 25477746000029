import Login from "./pages/Admin/Login";
import AdminHome from "./pages/Admin/Home";
import AdminBlog from "./pages/Admin/Blog";
import Homepage from "./pages/Homepage";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Services from "./pages/services";
import AdminCreateBlog from './pages/Admin/CreateBlog';
import Post from "./pages/Blog/post";

const routes = [
    {
        path: "/login",
        name: "Admin",
        component: Login,
        exact: true,
        isShown: false
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: AdminHome,
        exact: true,
        isShown: false,
        isAdmin: true,
        icon: 'fas fa-tachometer-alt'
    },
    {
        path: "/blog",
        name: "Blog",
        component: AdminBlog,
        exact: true,
        isShown: false,
        isAdmin: true,
        icon: 'fas fa-blog'
    },
    {
        path: "/create-blog",
        name: "Create Blog",
        component: AdminCreateBlog,
        exact: true,
        isShown: false,
        isAdmin: true,
        icon: 'fas fa-blog'
    },
    {
        path: "/edit-blog/:blogid",
        name: "Edit Blog",
        component: AdminCreateBlog,
        exact: true,
        isShown: false,
        isAdmin: false,
        icon: 'fas fa-blog'
    },
    {
        path: "/",
        name: "Home",
        component: Homepage,
        exact: true,
        isShown: true
    },
    {
        path: "/gallery",
        name: "Gallery",
        component: Gallery,
        exact: true,
        isShown: true
    },
    {
        path: "/about",
        name: "About",
        component: About,
        exact: true,
        isShown: true
    },
    {
        path: "/services",
        name: "Services",
        component: Services,
        exact: true,
        isShown: true
    },
    {
        path: "/blogs",
        name: "Blog",
        component: Blog,
        exact: true,
        isShown: true
    },
    {
        path: "/blog/:blogid?",
        name: "Blog",
        component: Post,
        exact: true,
        isShown: false
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        exact: true,
        isShown: true
    },
]

export default routes;