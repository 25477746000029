import React, { Component } from 'react'
import './style.css';
import { connect } from 'react-redux';
import { createBlog, deleteBlog } from '../../../Store/actions/blog';
import { compose } from 'redux';
import { firestoreConnect, withFirestore } from 'react-redux-firebase';

class AdminTextimonial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showForm: false,
            isformloading: false
        }
        if (!localStorage.getItem('isLoggedIn')) {
            this.props.history.push('/login');
        }
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {

    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    deleteBlog = blog => {
        if (blog.id)
            this.props.firestore.delete({ collection: 'textimonials', doc: blog.id })
    }



    onSubmit = e => {
        e.preventDefault();
        const payload = {
            name: this.state.name,
            msg: this.state.msg
        }
        this.props.firestore
            .collection("textimonials")
            .add({
                ...payload,
                authorId: this.props.uid,
                date: new Date(),
            })
            .then((resp) => {
                this.setState({
                    name: '',
                    msg: ''
                })
                // dispatch({
                //     type: "ADD_BLOG",
                //     blog,
                // });
            })
            .catch((err) => {
                console.log(err);
                // dispatch({
                //     type: "ADD_BLOG_ERR",
                //     err,
                // });
            });
        this.setState({
            isformloading: false
        })
    }

    render() {
        const { textimonials, isloading } = this.props;
        const { showForm, isformloading } = this.state;
        return (
            <div className="textimonial">
                <div className="row">
                    <h3 style={{ float: 'left' }}>Textimonials</h3>
                    <button className="btn btn-primary" onClick={e => this.setState({ showForm: !showForm })} style={{ float: 'right', marginTop: "20px" }}>{showForm ? 'Close' : 'ADD'}</button>
                </div>
                {
                    showForm && <div className="row"> <form onSubmit={e => { e.preventDefault(); this.setState({ isformloading: true }, () => this.onSubmit(e)) }} className="" noValidate="novalidate">
                        <div className="col-sm-12 col-lg-4">
                            <input style={{ width: '100%', marginTop: 10 }} type="text" onChange={this.onChange} value={this.state.name} placeholder="Name" name="name" size={40} className="w-100" />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <input style={{ width: '100%', marginTop: 10 }} type="text" onChange={this.onChange} value={this.state.msg} placeholder="Message" name="msg" size={40} className="w-100" />
                        </div>
                        <div className="col-sm-12 col-lg-4">
                            <button style={{ marginTop: 10 }} type="submit" className="btn btn-primary">Create</button>
                        </div>
                    </form>
                    </div>
                }
                <div className="row">
                    <table>
                        <tr>
                            <th>Name</th>
                            <th>Message</th>
                            <th>Action</th>
                        </tr>
                        {
                            (isloading || isformloading) && <tr>
                                <td colSpan={4}>Loading</td>
                            </tr>
                        }
                        {
                            (textimonials && (!isloading || !isformloading)) && textimonials.map(blog => {
                                return <tr>
                                    <td>{blog.name}</td>
                                    <td>{blog.msg}</td>
                                    <td>
                                        {/* <Link to={`edit-text/${blog.id}`}><i className="fa fa-edit"></i></Link> */}
                                        <i onClick={e => this.deleteBlog(blog)} className="fa fa-trash" style={{ color: 'red' }}></i>
                                    </td>
                                </tr>
                            })
                        }

                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
    const query = state.firestore.listeners.allIds.find(id => id.includes('textimonials'))
    return {
        uid,
        textimonials: state.firestore.ordered.textimonials,
        isloading: state.firestore.status.requesting[query]
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createBlog,
        deleteBlog
    };
};

export default compose(
    // firestoreConnect(() => ['textimonials']), // or { collection: 'todos' }
    // https://github.com/prescottprue/redux-firestore#document
    firestoreConnect((ownProps) => [
        {
            collection: "textimonials",
            orderBy: [['date', 'desc']]
        },
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(AdminTextimonial));
// export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog);