import React, { PureComponent } from 'react'
import MobileNav from '../../Components/mobile-nav'
import Header from '../../Components/header'
import Footer from '../../Components/footer';
import { openNav } from '../../Services/utils';

class Contact extends PureComponent {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <MobileNav />
                <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
                <div id="site">
                    <Header />
                    <main id="main" className="archive">
                        <div className="inner">
                            <div className="contact-wrapper">
                                <div className="contact-content">
                                    <h1 className="post-title">Contact Us</h1>
                                    <br />
                                    <p>I am Rupa Shah, I completed my graduation from commerce field and since 15 years I’m working as an insurance advisor with life corporation of India as well as a financial planner</p>
                                    <p>For your any query or doubt, Please contact me with this form or by using following details,</p><br />
                                    <p> <b>Phone:</b> <a href="tel:9725041234">9725041234</a> </p>
                                    <p> <b>Whatsapp:</b> <a href="https://wa.me/919725041234">9725041234</a> </p>
                                    <p> <b>Email:</b> <a href="mailto:rupashah57@yahoo.in" rel="noopener noreferrer" target="_blank">rupashah57@yahoo.in</a> </p>
                                </div>
                                <div className="contact-form">
                                    <div role="form" className="wpcf7" id="wpcf7-f9-p30-o1" lang="en-US" dir="ltr">
                                        <div className="screen-reader-response" aria-live="polite" />
                                        <form method="post" className="wpcf7-form" noValidate="novalidate">
                                            <div style={{ display: 'none' }}>
                                                <input type="hidden" name="_wpcf7" defaultValue={9} />
                                                <input type="hidden" name="_wpcf7_version" defaultValue="5.1.9" />
                                                <input type="hidden" name="_wpcf7_locale" defaultValue="en_US" />
                                                <input type="hidden" name="_wpcf7_unit_tag" defaultValue="wpcf7-f9-p30-o1" />
                                                <input type="hidden" name="_wpcf7_container_post" defaultValue={30} />
                                                <input type="hidden" name="g-recaptcha-response" defaultValue />
                                            </div>
                                            <p><label> Your Name (required)<br />
                                                <span className="wpcf7-form-control-wrap your-name"><input type="text" name="your-name" size={40} className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required" aria-required="true" aria-invalid="false" /></span> </label></p>
                                            <p><label> Your Email (required)<br />
                                                <span className="wpcf7-form-control-wrap your-email"><input type="email" name="your-email" size={40} className="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email" aria-required="true" aria-invalid="false" /></span> </label></p>
                                            <p><label> Subject<br />
                                                <span className="wpcf7-form-control-wrap your-subject"><input type="text" name="your-subject" size={40} className="wpcf7-form-control wpcf7-text" aria-invalid="false" /></span>
                                            </label></p>
                                            <p><label> Your Message<br />
                                                <span className="wpcf7-form-control-wrap your-message"><textarea name="your-message" cols={40} rows={10} className="wpcf7-form-control wpcf7-textarea" aria-invalid="false" /></span> </label></p>
                                            <p><input type="submit" defaultValue="Send" className="wpcf7-form-control wpcf7-submit" /></p>
                                            <div className="wpcf7-response-output wpcf7-display-none" aria-hidden="true" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Contact;