import React, { Component } from 'react'
import MobileNav from '../../Components/mobile-nav'
import Header from '../../Components/header'
import Footer from '../../Components/footer';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { openNav } from '../../Services/utils';
import Image from '../../Components/image';

const options = {
    settings: {
        disableKeyboardControls: true,
    },
    thumbnails: {
        showThumbnails: false,
    },
    progressBar: {
        showProgressBar: false
    },
    buttons: {
        showFullscreenButton: false,
        showNextButton: false,
        showPrevButton: false,
        showAutoplayButton: false,
    }
}

const images = [
    {
        path: "assets/images/gallery/g-1.jpeg"
    },
    {
        path: "assets/images/gallery/g-2.jpeg"
    },
    {
        path: "assets/images/gallery/g-3.jpeg"
    },
    {
        path: "assets/images/gallery/g-4.jpeg"
    },
    {
        path: "assets/images/gallery/g-5.jpeg"
    },
    {
        path: "assets/images/gallery/g-6.jpeg"
    },
    {
        path: "assets/images/gallery/g-7.jpeg"
    },
    {
        path: "assets/images/gallery/g-8.jpeg"
    },
    {
        path: "assets/images/gallery/g-9.jpeg"
    },
    {
        path: "assets/images/gallery/g-10.jpeg"
    },
    {
        path: "assets/images/gallery/g-11.jpeg"
    },
    {
        path: "assets/images/gallery/g-12.jpeg"
    }
]

class Gallery extends Component {
    constructor(props) {
        super(props);
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <MobileNav />
                <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
                <div id="site">
                    <Header />
                    <main id="main" className="portfolio">
                        <div className="inner">
                            <h1 className="page-title">Gallery</h1>
                            <SimpleReactLightbox>
                                {
                                    images.map((item, i) => (
                                        <article key={(i + 1)} id="post-189">
                                            <div className="post-thumbnail">
                                                <SRLWrapper options={options}>
                                                    <a href={item.path}>
                                                        <Image width={500} height={500} src={item.path} alt="" sizes="(max-width: 500px) 100vw, 500px" />
                                                    </a>
                                                </SRLWrapper>
                                            </div>
                                            {/* <div className="post-content">
                                                    <h2 className="post-title"><a href="example-portfolio-3/index.html" rel="bookmark">Example Portfolio 3</a></h2>
                                                </div> 
                                            */}
                                        </article>
                                    ))
                                }

                            </SimpleReactLightbox>
                        </div>
                    </main>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default Gallery;