import React from 'react'
import './admin-header.css';
import routes from '../../routes';
import { Link, withRouter } from 'react-router-dom';
import { signOut } from '../../Store/actions/auth';
import { connect } from 'react-redux';

function MobileNavBar(props) {
    const logout = e => {
        e.preventDefault();
        props.signOut()
    }
    return (
        <nav class="mobile-bottom-nav">
            {
                routes.filter(r => (r.isAdmin)).map(link => {
                    const isAction = (props.location.pathname === link.path);
                    return <div class={`mobile-bottom-nav__item`}>
                        <Link to={link.path}>
                            <div className={`mobile-bottom-nav__item-content ${isAction ? 'mobile-bottom-nav__item--active' : ''}`}>
                                <i className={link.icon}></i>
                                {link.name}
                            </div>
                        </Link>
                    </div>
                })
            }
            <div class={`mobile-bottom-nav__item`}>
                <a href="true" onClick={logout}>
                    <div class="mobile-bottom-nav__item-content">
                        <i className="fas fa-sign-out-alt"></i>
                        {'Logout'}
                    </div>
                </a>
            </div>
        </nav>
    )
}
const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid;
    return {
        uid: uid,
        error: state.auth.error
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MobileNavBar))