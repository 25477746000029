import React, { Component } from 'react';
import './style.css';
import Axios from 'axios';
import { compose } from 'redux';
import { firestoreConnect, withFirestore } from 'react-redux-firebase';
import { connect } from 'react-redux';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonials: []
        }
    }
    componentDidMount() {
        Axios.get('/assets/json/testimonial.json')
            .then(resp => {
                if (resp.data) {
                    this.setState({
                        testimonials: resp.data
                    })
                }
            })
            .catch(err => {
                console.error(err)
            })
    }
    render() {
        const { textimonials, isloading } = this.props;
        return isloading ? 'Loading...' : (
            <div className="testimony">
                <div>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/quote.png`} className="img-responsive quote-image" alt="quote" />
                </div>
                {/* Carousel Start below */}
                <div id="carousel1" className="carousel slide testimonial-style" style={{ display: 'block' }} data-ride="carousel">
                    {/* Indicators */}
                    <ol className="carousel-indicators">
                        {
                            !!textimonials && textimonials.map((msg, index) => (
                                <li data-target={`#carousel${index}`} data-slide-to={(index + 1)} className={`${index === 0 ? 'active' : ''}`} />
                            ))
                        }
                    </ol>
                    {/* Wrapper for slides */}
                    <div className="carousel-inner">
                        {
                            !!textimonials && textimonials.map((msg, index) => (
                                <div key={index} className={`item ${index === 0 ? 'active' : ''}`}>
                                    <div className="carousel-caption testimonial-caption-style">
                                        <p>{msg.msg}</p>
                                        <p className="testimonial-authors">{msg.name}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/* Controls */}
                    <a className="left carousel-control" href="#carousel1" data-slide="prev"> <i className="glyphicon glyphicon-chevron-left" /> </a>
                    <a className="right carousel-control" href="#carousel1" data-slide="next"> <i className="glyphicon glyphicon-chevron-right" /> </a>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
    const query = state.firestore.listeners.allIds.find(id => id.includes('textimonials'))
    return {
        uid,
        textimonials: state.firestore.ordered.textimonials,
        isloading: state.firestore.status.requesting[query]
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default compose(
    // firestoreConnect(() => ['blogs']), // or { collection: 'todos' }
    firestoreConnect((ownProps) => {
        return [
            {
                collection: "textimonials",
                orderBy: [['date', 'desc']]
            },
        ]
    }),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(Testimonial));