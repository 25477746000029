import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import routes from '../../routes';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <>
        <header>
          <div className="logo">
            <Link to="/"><img alt="logo" src={`${process.env.PUBLIC_URL}/assets/images/rush-logo.png`} style={{ width: '60%' }} /></Link>
          </div>
          <nav className="navigation-top">
            <div className="menu-main-menu-container">
              <ul id="top-menu" className="menu">
                {
                  routes.filter(r => r.isShown).map((link, i) => {
                    let isMatch = (this.props.location.pathname === link.path)
                    return (
                      <li key={i} className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-4${i}`}>
                        <Link style={{ borderBottom: isMatch ? '1px black solid' : '' }} to={link.path}>{link.name}</Link>
                      </li>
                    )
                  })
                }
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-41">
                  <span> <a href="tel:9725041234" style={{ padding: '0 1em' }}><i className="fa fa-phone"></i> 9725041234</a> <a href="mailto:rupashah57@yahoo.in" rel="noopener noreferrer" target="_blank"> <i className="fa fa-envelope-o"></i> rupashah57@yahoo.in</a> </span>
                </li>
                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-42"><Link to="services">Services</Link></li> */}
                {/* <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-has-children menu-item-45">
                    <a href="index.html" aria-current="page">Page Examples</a>
                    <ul className="sub-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-44"><a href="sample-page">Page Builder</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-39"><a href="standard-page">Basic Page</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-155"><a href="colour-page-builder">Colour Page Builder</a></li>
                    </ul>
                  </li>
                  <li className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item current_page_item menu-item-has-children menu-item-204">
                    <a href="index.html" aria-current="page">Features</a>
                    <ul className="sub-menu">
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-50"><a href="shop">Shop</a></li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-207"><a href="product/test-product-one">Product</a></li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-206"><a href="portfolio">Portfolio</a></li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-portfolio menu-item-205">
                        <a href="portfolio/example-portfolio-3">Portfolio Item</a></li>
                    </ul>
                  </li> */}
                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-43">
                <Link to="blogs">Blog</Link>
                <ul className="sub-menu">
                  <li className="menu-item menu-item-type-post_type menu-item-object-post menu-item-208"><a href="2019/08/17/another-sample-post">Blog Post</a></li>
                  <li className="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-209"><a href="category/demo">Archive</a></li>
                </ul>
              </li> */}
                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-40"><Link to="contact">Contact</Link></li> */}
              </ul>
            </div>
            {/* <div className="search-icon">
            <div className="search-icon-wrapper"><i className="fas fa-search" /></div>
          </div> */}
          </nav>
          {/* <div className="search-box">
          <form role="search" method="get" className="search-form">
            <label htmlFor="search-form-5efdae325b1d5">
              <span className="screen-reader-text">Search for:</span>
            </label>
            <input type="search" id="search-form-5efdae325b1d5" className="search-field" placeholder="Search…" defaultValue name="s" />
            <button type="submit" className="search-submit" value="Search"><i className="fas fa-search" /></button>
          </form>
        </div> */}
        </header>
      </>
    )
  }
}



export default withRouter(Header);