import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './Store';
import App from './App';
import './index.css';
import { createFirestoreInstance } from 'redux-firestore';
import { getFirebaseInstance } from './config/firebaseConfig';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

const firebase = getFirebaseInstance();
const rrfconfig = {
    firebase,
    config: {},
    dispatch: store.dispatch,
    createFirestoreInstance
};

console.log = () => { };
console.error = () => { };

ReactDOM.render(
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfconfig}>
            <App />
        </ReactReduxFirebaseProvider>
    </Provider>, document.getElementById('root'));
