import React, { Component } from 'react'
import './style.css';
import AdminHeader from '../../../Components/admin-header'
import { connect } from 'react-redux';
import AdminTextimonial from '../Textimonial';
import { compose } from 'redux';
import { firestoreConnect, withFirestore } from 'react-redux-firebase';
import { withRouter } from 'react-router';

class AdminHome extends Component {

    constructor(props) {
        super(props)
        if (!localStorage.getItem('isLoggedIn')) {
            window.location.href = '/login'
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <AdminHeader />
                <div className="page-container" style={styleSheet}>
                    <h2>Dashboard</h2>
                    <div class="dashboard">
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                                <h3>Welcome back, Rupa Shah</h3>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3">
                                <div class="card bg-c-blue order-card">
                                    <div class="card-block">
                                        <h2 class="text-right"><i class="fa fas fa-blog f-left"></i><span>{this.props.isblogloading ? '-' : this.props.blogs && this.props.blogs.length}</span></h2>
                                        <p class="m-b-0">Total Blogs</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3">
                                <div class="card bg-c-green order-card">
                                    <div class="card-block">
                                        <h2 class="text-right"><i class="fa fa-id-card f-left"></i><span>{this.props.istextloading ? '-' : this.props.textimonials && this.props.textimonials.length}</span></h2>
                                        <p class="m-b-0">Total Testomials</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-sm-12 col-md-12 col-xl-12">
                                <AdminTextimonial />
                            </div>

                            {/* <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3">
                                    <div class="card bg-c-yellow order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fa-refresh f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Completed Orders</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xs-12 col-sm-12 col-md-3 col-xl-3">
                                    <div class="card bg-c-pink order-card">
                                        <div class="card-block">
                                            <h2 class="text-right"><i class="fa fa-credit-card f-left"></i><span>486</span></h2>
                                            <p class="m-b-0">Completed Orders</p>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const styleSheet = {
    margin: '3vw 10vw'
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
    const textQuery = state.firestore.listeners.allIds.find(id => id.includes('textimonials'));
    const blogQuery = state.firestore.listeners.allIds.find(id => id.includes('blogs'));
    return {
        uid,
        error: state.auth.error,
        islogged: state.auth.islogged,
        blogs: state.firestore.ordered.blogs,
        isblogloading: state.firestore.status.requesting[blogQuery],
        textimonials: state.firestore.ordered.textimonials,
        istextloading: state.firestore.status.requesting[textQuery]
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default compose(
    // firestoreConnect(() => ['textimonials']), // or { collection: 'todos' }
    // https://github.com/prescottprue/redux-firestore#document
    firestoreConnect((ownProps) => [
        {
            collection: "textimonials",
            orderBy: [['date', 'desc']]
        },
        {
            collection: "blogs",
            orderBy: [['date', 'desc']]
        },
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(withRouter(AdminHome)));