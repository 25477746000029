const blogReducer = (state = { islogged: false }, action) => {
    switch (action.type) {
        case "SIGN_IN":
            return {
                ...state,
                islogged: true,
            };
        case "SIGN_IN_ERR":
            return {
                ...state,
                islogged: false,
                error: action.err.message
            };
        case "SIGN_OUT":
            return {
                ...state,
                islogged: false
            };
        case "SIGN_UP":
            return { ...state };
        case "SIGN_UP_ERR":
            return { ...state };
        default:
            return state;
    }
};

export default blogReducer;