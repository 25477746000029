import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <footer className="site-footer">
        <div className="inner">
          <div className="social-footer">
            <a href="http://www.facebook.com/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-f" /></a>
            <a href="http://www.twitter.com/" rel="noopener noreferrer" target="_blank"><i className="fab fa-linkedin" /></a>
            <a href="http://www.instagram.com/" rel="noopener noreferrer" target="_blank"><i className="fab fa-instagram" /></a>
            <a href="/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/images/lic.jpg`} width="25" alt="lic" /></a>
          </div>
          <div className="footer-menu">
            <div className="menu-footer-container">
              <ul id="footer-menu" className="menu">
                <li id="menu-item-98" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98"><Link to="/about">About</Link></li>
                <li id="menu-item-99" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-99"><Link to="/contact">Contact</Link></li>
                <li id="menu-item-100" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-100"><Link to="/services">Services</Link></li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <p>Copyright © Rush Investments 2020</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;