import React, { Component } from 'react'
import './style.css';
import AdminHeader from '../../../Components/admin-header'
import { connect } from 'react-redux';
import { createBlog, deleteBlog } from '../../../Store/actions/blog';
import { compose } from 'redux';
import { firestoreConnect, withFirestore } from 'react-redux-firebase';
import { Link } from 'react-router-dom';
import { ReactComponent as BlogLoader } from '../../../Assets/blog-loading.svg';
import { ReactComponent as NoData } from '../../../Assets/no-data.svg';

class AdminBlog extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editorHtml: '',
            theme: 'snow'
        }
        if (!localStorage.getItem('isLoggedIn')) {
            this.props.history.push('/login');
        }
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {

    }

    onEditorChange = (html) => {
        this.setState({ editorHtml: html });
    }

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    deleteBlog = blog => {
        if (blog.id)
            this.props.firestore.delete({ collection: 'blogs', doc: blog.id })
    }

    render() {
        const { blogs, isloading } = this.props;
        return (
            <div>
                <div>
                    <AdminHeader />
                    <div className="page-container" style={styleSheet}>
                        <h2>Blog</h2>
                        <table>
                            <tr>
                                <th>ID</th>
                                <th>Title</th>
                                <th>Creator</th>
                                <th>Action</th>
                            </tr>
                            {
                                isloading && <tr>
                                    <td colSpan={4}>Loading</td>
                                </tr>
                            }
                            {
                                (blogs && !isloading) && blogs.map(blog => {
                                    return <tr>
                                        <td>{blog.id}</td>
                                        <td>{blog.title}</td>
                                        <td>{'Rupa'}</td>
                                        <td> <Link to={`edit-blog/${blog.id}`}><i className="fa fa-edit"></i></Link> <i onClick={e => this.deleteBlog(blog)} className="fa fa-trash" style={{ color: 'red' }}></i> </td>
                                    </tr>
                                })
                            }

                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const styleSheet = {
    margin: '3vw 10vw',
    marginBottom: '10vh'
}

const mapStateToProps = (state) => {
    const uid = state.firebase.auth.uid ? state.firebase.auth.uid : null;
    const query = state.firestore.listeners.allIds.find(id => id.includes('blogs'))
    return {
        uid,
        blogs: state.firestore.ordered.blogs,
        isloading: state.firestore.status.requesting[query]
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        createBlog,
        deleteBlog
    };
};

export default compose(
    // firestoreConnect(() => ['blogs']), // or { collection: 'todos' }
    // https://github.com/prescottprue/redux-firestore#document
    firestoreConnect((ownProps) => [
        {
            collection: "blogs",
            orderBy: [['date', 'desc']]
        },
    ]),
    connect(mapStateToProps, mapDispatchToProps)
)(withFirestore(AdminBlog))
// export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog);