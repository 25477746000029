export const signIn = creds => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        return firebase
            .auth()
            .signInWithEmailAndPassword(creds.email, creds.password)
            .then((resp) => {
                dispatch({ type: "SIGN_IN" });
                localStorage.setItem('userInfo', btoa(JSON.stringify(resp.user)))
                localStorage.setItem('isLoggedIn', 'true')

                return resp;

            })
            .catch(err => {
                dispatch({ type: "SIGN_IN_ERR", err }, err);
            });
    };
};

export const signOut = () => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        return firebase
            .auth()
            .signOut()
            .then((resp) => {
                dispatch({ type: "SIGN_OUT" });
                localStorage.clear();
                window.location.href = '/login';
            });
    };
};

export const signUp = creds => {
    return (dispatch, getState, { getFirebase }) => {
        const firebase = getFirebase();

        firebase
            .auth()
            .createUserWithEmailAndPassword(creds.email, creds.password)
            .then(() => {
                dispatch({ type: "SIGN_UP" });
            })
            .catch(err => {
                dispatch({ type: "SIGN_UP_ERR" }, err);
            });
    };
};