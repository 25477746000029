import React, { Component } from 'react'
import MobileNav from '../../Components/mobile-nav'
import Header from '../../Components/header'
import Footer from '../../Components/footer';
import { openNav } from '../../Services/utils';
import { withFirestore } from 'react-redux-firebase';
import moment from 'moment';

class Post extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isloading: true,
            blog: {},
            blogId: props.match.params ? props.match.params.blogid : null
        }
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        if (this.state.blogId) {
            this.props.firestore.get({ collection: 'blogs', doc: this.state.blogId }).then(doc => {
                this.setState({
                    isloading: false,
                    blog: doc.data()
                })
            })
        }
    }

    render() {
        const { blog, isloading } = this.state
        return (
            <div>
                <MobileNav />
                <span onClick={e => openNav()} className="open-mobile-navigation"><i className="fa fa-bars" /></span>
                <div id="site">
                    <Header />
                    <main id="main" className="single-post">
                        {
                            isloading ? <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo">LOADING...</article> : ''
                        }
                        {
                            !blog ? <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo"> No data</article> : ''
                        }
                        {
                            (!isloading && !!blog) && <article id="post-201" className="post-201 post type-post status-publish format-standard has-post-thumbnail hentry category-demo">
                                <h1 className="post-title">{blog.title}</h1>
                                <div className="post-meta">
                                    <div className="post-date">
                                        {moment(blog.date.toDate()).format('DD MMM YYYY hh:ss A')}
                                    </div>
                                </div>
                                <div className="post-thumbnail">
                                    <img width={1000} height={800} src={blog.coverImg} alt={blog.id} />
                                </div>
                                <div className="post-content">
                                    <div className="entry-content" dangerouslySetInnerHTML={{ __html: blog.content }}>

                                    </div>
                                </div>
                            </article>}
                    </main>
                    <Footer />
                </div>
            </div>
        )
    }
}

export default withFirestore(Post);