import React from "react";
import "./image.css";

const Image = props => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    return (
        <React.Fragment>
            <img
                className=" thumb"
                {...props}
                src={`${process.env.PUBLIC_URL}/assets/images/default.jpg`}
                alt="default"
                style={{ display: isLoaded ? "none" : "block" }}
            />
            <img
                onLoad={() => {
                    setIsLoaded(true);
                }}
                {...props}
                style={{ opacity: isLoaded ? 1 : 0 }}
                alt={props.alt}
                src={props.src}
            />
        </React.Fragment>
    );
};
export default Image;