import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'


const config = {
    apiKey: "AIzaSyDg5uhbnL5rqn9YlE6uBBBvZHJwwFC0uDA",
    authDomain: "rushinvestments-dd9f1.firebaseapp.com",
    databaseURL: "https://rushinvestments-dd9f1.firebaseio.com",
    projectId: "rushinvestments-dd9f1",
    storageBucket: "rushinvestments-dd9f1.appspot.com",
    messagingSenderId: "234809893172",
    appId: "1:234809893172:web:479b3ee391e3f8a3834561"
};
//We are adding settings to the firebase object.
let firebaseCache;

export const getFirebaseInstance = () => {
    if (firebaseCache) {
        return firebaseCache;
    }

    firebase.initializeApp(config);
    firebase.firestore();
    firebaseCache = firebase;
    return firebase;
};