export const createBlog = (blog) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        const authorId = getState().firebase.auth.uid
        debugger
        firestore
            .collection("blogs")
            .add({
                ...blog,
                authorId: authorId,
                date: new Date(),
            })
            .then(() => {
                dispatch({
                    type: "ADD_BLOG",
                    blog,
                });
            })
            .catch((err) => {
                dispatch({
                    type: "ADD_BLOG_ERR",
                    err,
                });
            });
    };
};

export const deleteBlog = (blog) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();
        firestore
            .collection("blogs")
            .doc(blog.id)
            .delete()
            .then(() => {
                dispatch({
                    type: "REMOVE_BLOG",
                });
            })
            .catch((err) => {
                dispatch({
                    type: "REMOVE_BLOG_ERR",
                    err,
                });
            });
    };
};

export const UpdateBlog = (blog) => {
    return (dispatch, getState, { getFirebase }) => {
        const firestore = getFirebase().firestore();

        firestore
            .collection("blogs")
            .doc(blog.id)
            .set(
                {
                    ...blog,
                    checked: !blog.checked,
                },
                { merge: true }
            )
            .then(() => {
                dispatch({
                    type: "TOGGLE_CHECKED",
                    blog,
                });
            })
            .catch((err) => {
                dispatch({
                    type: "TOGGLE_CHECKED_ERR",
                    err,
                });
            });
    };
};